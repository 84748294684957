import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private token: BehaviorSubject<string> = new BehaviorSubject("");
  constructor() { }

  setToken(token: string) {
    this.token.next(token);
  }

  getToken(): BehaviorSubject<string> {
    return this.token;
  }


}
