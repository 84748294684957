import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, forkJoin } from 'rxjs'
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators'

import { AlertService } from '../alert/alert.service'
import { TokenService } from '../token/token.service'
import { EnvService } from '../env/env.service'

import {
    Monte,
    Horas,
    Equipo,
    Usuario,
    Asignacion,
    Cabezal,
    Cliente,
    Movimiento,
    PreciosVenta,
    OtroDato,
    CosteOperario,
    CosteEquipo,
    RentabilidadMonte,
    RentabilidadMes,
    CosteMonte,
} from 'src/app/models/models'

interface LoadingValue<T> {
    value: T
    error?: any
    loading?: boolean
}

@Injectable({
    providedIn: 'root',
})
export class DataService {
    /* TOKEN VARS */
    public token: BehaviorSubject<string> = new BehaviorSubject('')
    private headers: HttpHeaders = new HttpHeaders()

    /* STATUS DATA VARS */
    public isDataReady: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /*******************************************************/
    /************* XESTOR DE RECURSOS VARS *****************/
    /*******************************************************/

    public asignaciones: BehaviorSubject<LoadingValue<Asignacion[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Asignacion[]>)
    public myAsignaciones: BehaviorSubject<LoadingValue<Asignacion[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Asignacion[]>)
    public montes: BehaviorSubject<LoadingValue<Monte[]>> = new BehaviorSubject(
        {
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Monte[]>,
    )
    public horas: BehaviorSubject<LoadingValue<Horas[]>> = new BehaviorSubject({
        loading: false,
        value: [],
        error: null,
    } as LoadingValue<Horas[]>)
    public equipos: BehaviorSubject<LoadingValue<Equipo[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Equipo[]>)
    public cabezales: BehaviorSubject<LoadingValue<Cabezal[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Cabezal[]>)

    public usuarios: BehaviorSubject<LoadingValue<Usuario[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Usuario[]>)

    public montes_assigned_to_user: BehaviorSubject<Monte[]> =
        new BehaviorSubject([] as Monte[])
    public equipos_assigned_to_user: BehaviorSubject<Equipo[]> =
        new BehaviorSubject([] as Equipo[])
    public uniqueActividades: BehaviorSubject<string[]> = new BehaviorSubject(
        [] as string[],
    )

    /*******************************************************/
    /************* XESTOR FINANCIERO VARS *****************/
    /*******************************************************/
    public clientes: BehaviorSubject<LoadingValue<Cliente[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Cliente[]>)
    public movimientos: BehaviorSubject<LoadingValue<Movimiento[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<Movimiento[]>)
    public precios_ventas: BehaviorSubject<LoadingValue<PreciosVenta[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<PreciosVenta[]>)
    public otros_datos: BehaviorSubject<LoadingValue<OtroDato[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<OtroDato[]>)
    public coste_operarios: BehaviorSubject<LoadingValue<CosteOperario[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<CosteOperario[]>)
    public coste_equipos: BehaviorSubject<LoadingValue<CosteEquipo[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<CosteEquipo[]>)
    public all_coste_monte: BehaviorSubject<LoadingValue<CosteMonte[]>> =
        new BehaviorSubject({
            loading: false,
            value: [],
            error: null,
        } as LoadingValue<CosteMonte[]>)

    /*******************************************************/
    /**************** RENTABILIDADES VARS *****************/
    /*******************************************************/
    public rentabilidad_monte: BehaviorSubject<RentabilidadMonte> =
        new BehaviorSubject({} as RentabilidadMonte)
    public rentabilidad_mes: BehaviorSubject<LoadingValue<RentabilidadMes[]>> =
        new BehaviorSubject({
            loading: false,
            value: [] as RentabilidadMes[],
            error: null,
        } as LoadingValue<RentabilidadMes[]>)

    /*******************************************************/
    /**************** CONSTRUCTOR *****************/
    /*******************************************************/
    constructor(
        private tokenService: TokenService,
        private alertService: AlertService,
        private http: HttpClient,
        private env: EnvService,
    ) {
        /* Gets cuando el token está listo */
        this.token = this.tokenService.getToken()
        this.token.pipe(distinctUntilChanged()).subscribe((x) => {
            if (x || x !== '') {
                this.headers = new HttpHeaders({
                    Authorization: 'Bearer ' + x,
                    Accept: 'application/json',
                })

                this.isDataReady.next(true)
            }
        })
    }

    /*********************************************************************/
    //** XESTOS DE RECUROS - FETCH METHODS *******************************/
    /*********************************************************************/
    public async fetchMontes(completed: boolean = true): Promise<Monte[]> {
        if (this.montes.value.loading) return []
        this.montes.next({ ...this.montes.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Monte[]>(
                    this.env.API_URL +
                        'montes' +
                        (completed ? '?completed=true' : ''),
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Monte[]) => {
                        return response || []
                    }),
                    tap((montes: any) => {
                        //////console.log("MONTES: ", montes);
                        /* Invertir ordena montes.equipos*/
                        this.montes.next({
                            value: montes.montes.reverse(),
                            loading: false,
                        })
                        this.montes_assigned_to_user.next(
                            montes.montes_assigned_to_user,
                        )
                    }),
                ),
        )
    }

    public async fetchUsuarios(): Promise<Usuario[]> {
        if (this.usuarios.value.loading) return []
        this.usuarios.next({ ...this.usuarios.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Usuario[]>(this.env.API_URL + 'users', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: any) => {
                        return response.users || []
                    }),
                    tap((users: Usuario[]) => {
                        this.usuarios.next({
                            loading: false,
                            value: users.map((x) => {
                                return {
                                    pending:
                                        x.invitation_accepted_at == null &&
                                        x.invitation_created_at != null,
                                    ...x,
                                }
                            }),
                        })
                    }),
                ),
        )
    }

    public async fetchHoras(): Promise<Horas[]> {
        if (this.horas.value.loading) return []
        this.horas.next({ ...this.horas.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Horas[]>(this.env.API_URL + 'horas', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Horas[]) => {
                        return response || []
                    }),
                    tap((horas: Horas[]) => {
                        this.horas.next({
                            loading: false,
                            value: horas.reverse(),
                        })
                        //////console.log("HORAS: ", horas);
                    }),
                ),
        )
    }

    public async fetchEquipos(): Promise<Equipo[]> {
        if (this.equipos.value.loading) return []
        this.equipos.next({ ...this.equipos.value, loading: true })

        return firstValueFrom(
            this.http
                .get<Equipo[]>(this.env.API_URL + 'equipos', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Equipo[]) => {
                        return response || []
                    }),
                    tap((equipos: any) => {
                        this.equipos.next({
                            loading: false,
                            value: equipos.equipos.reverse(),
                        })
                        this.equipos_assigned_to_user.next(
                            equipos.equipos_assigned_to_user,
                        )
                    }),
                ),
        )
    }

    public async fetchCabezales(): Promise<Cabezal[]> {
        if (this.cabezales.value.loading) return []
        this.cabezales.next({ ...this.cabezales.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Cabezal[]>(this.env.API_URL + 'cabezales', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Cabezal[]) => {
                        return response || []
                    }),
                    tap((cabezales: any) => {
                        var aux = {
                            value: cabezales.reverse(),
                            loading: false,
                        }
                        this.cabezales.next(aux)
                    }),
                ),
        )
    }

    public async fetchAsignaciones(completed: boolean = false): Promise<{
        asignaciones: Asignacion[]
        my_asignaciones: Asignacion[]
    }> {
        if (
            this.asignaciones.value.loading &&
            this.myAsignaciones.value.loading
        )
            return {
                asignaciones: [],
                my_asignaciones: [],
            }
        this.asignaciones.next({ ...this.asignaciones.value, loading: true })
        return firstValueFrom(
            this.http
                .get<{
                    asignaciones: Asignacion[]
                    my_asignaciones: Asignacion[]
                }>(
                    this.env.API_URL +
                        'asignaciones' +
                        (completed ? '?completed=true' : ''),
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map(
                        (response: {
                            asignaciones: Asignacion[]
                            my_asignaciones: Asignacion[]
                        }) => {
                            return (
                                response || {
                                    asignaciones: [],
                                    my_asignaciones: [],
                                }
                            )
                        },
                    ),
                    tap(
                        (asignaciones: {
                            asignaciones: Asignacion[]
                            my_asignaciones: Asignacion[]
                        }) => {
                            this.asignaciones.next({
                                value: asignaciones.asignaciones,
                                loading: false,
                            })
                            this.myAsignaciones.next({
                                value: asignaciones.my_asignaciones,
                                loading: false,
                            })
                            //////console.log("ASIGNACIONES: ", asignaciones);
                        },
                    ),
                ),
        )
    }

    public fetchUniqueActividades(): Promise<string[]> {
        return firstValueFrom(
            this.http
                .get<string[]>(this.env.API_URL + 'cabezales/actividades', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: string[]) => {
                        return response || []
                    }),
                    tap((actividades: string[]) => {
                        this.uniqueActividades.next(actividades)
                        //////console.log("UNIQUE ACTIVIDADES: ", actividades);
                    }),
                ),
        )
    }

    /*********************************************************************/
    /** XESTOR DE RECUROS - UPDATE/POST METHODS **************************/
    /*********************************************************************/

    //HORAS/////////////////////////////////////////////////////////////
    public updateHoras(horas: Horas): Promise<Horas> {
        return firstValueFrom(
            this.http
                .put<Horas>(this.env.API_URL + 'horas/' + horas.uuid, horas, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Horas) => {
                        return response
                    }),
                    tap((horas: Horas) => {
                        this.fetchHoras()
                        return horas
                    }),
                ),
        )
    }

    public postHoras(aux: any): Promise<any> {
        const formData = new FormData()
        formData.append('actividad', aux.actividad)
        formData.append('asignacion_uuid', aux.asignacion_uuid)
        formData.append('consumo_medio', aux.consumo_medio)
        formData.append('dia', new Date(aux.dia).toISOString())
        formData.append('horas_inicio', aux.horas_inicio)
        formData.append('horas_fin', aux.horas_fin)
        formData.append('horas_operario', aux.horas_operario)
        formData.append('observaciones', aux.observaciones)
        formData.append('foto', aux.foto)
        ////////console.log("aux: ", aux);
        ////console.log('formData: ', aux)
        return firstValueFrom(
            this.http
                .post<Horas>(this.env.API_URL + 'horas/', formData, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        //check if evironment debug = true
                        if (
                            this.env.DEBUG &&
                            error.name == 'HttpErrorResponse'
                        ) {
                            ////////console.log("error: ", error);
                            this.alertService.showHTMLAlert(
                                'Error',
                                error.error.message || error.message,
                            )
                        }
                        throw error
                    }),
                    map((response: Horas) => {
                        ////////console.log("response: ", response);
                        return response
                    }),
                    tap((horas: Horas) => {
                        this.fetchHoras()
                        return horas
                    }),
                ),
        )
    }

    public removeHoras(horas: Horas): Promise<Horas> {
        return firstValueFrom(
            this.http
                .delete<Horas>(this.env.API_URL + 'horas/' + horas.uuid, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Horas) => {
                        this.fetchHoras()
                        return response
                    }),
                ),
        )
    }

    //EQUIPO/////////////////////////////////////////////////////////////
    public updateEquipo(equipo: Equipo): Promise<Equipo> {
        return firstValueFrom(
            this.http
                .put<Equipo>(
                    this.env.API_URL + 'equipos/' + equipo.uuid,
                    equipo,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Equipo) => {
                        return response
                    }),
                    tap((equipo: Equipo) => {
                        const index = this.equipos.value.value.findIndex(
                            (x) => x.uuid === equipo.uuid,
                        )
                        this.equipos.value.value[index] = equipo
                        this.equipos.next(this.equipos.value)
                    }),
                ),
        )
    }

    public postEquipo(equipo: Equipo): Promise<Equipo> {
        return firstValueFrom(
            this.http
                .post<Equipo>(this.env.API_URL + 'equipos/', equipo, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Equipo) => {
                        return response
                    }),
                    tap((equipo: Equipo) => {
                        this.fetchEquipos()
                        return equipo
                    }),
                ),
        )
    }

    public postEquipoCabezales(equipo: Equipo, cabezales: Cabezal[]) {
        if (cabezales.length) {
            equipo.cabezales = cabezales
        }
        ////console.log('Equipo a postear: ', equipo)
        return firstValueFrom(
            this.http
                .post<Equipo>(this.env.API_URL + 'equipos', equipo, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        //check if evironment debug = true
                        if (
                            this.env.DEBUG &&
                            error.name == 'HttpErrorResponse'
                        ) {
                            ////////console.log("error: ", error);
                            this.alertService.showHTMLAlert(
                                'Error',
                                error.error.message || error.message,
                            )
                        }
                        throw error
                    }),
                    map((response: Equipo) => {
                        return response
                    }),
                    tap((equipo: Equipo) => {
                        this.fetchEquipos()
                        return equipo
                    }),
                ),
        )
    }

    public removeEquipo(equipo: Equipo): Promise<Equipo> {
        return firstValueFrom(
            this.http
                .delete<Equipo>(this.env.API_URL + 'equipos/' + equipo.uuid, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Equipo) => {
                        return response
                    }),
                ),
        )
    }

    public postInactivoCabezal(inactivoData: any): Promise<any> {
        return firstValueFrom(
            this.http
                .post<any>(
                    this.env.API_URL + 'cabezales/inactivo',
                    inactivoData,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        //check if evironment debug = true
                        if (
                            this.env.DEBUG &&
                            error.name == 'HttpErrorResponse'
                        ) {
                            ////////console.log("error: ", error);
                            this.alertService.showHTMLAlert(
                                'Error',
                                error.error.message || error.message,
                            )
                        }
                        throw error
                    }),
                    map((response: any) => {
                        return response
                    }),
                    tap((inactivo: any) => {
                        //////console.log("INACTIVO: ", inactivo);
                    }),
                ),
        )
    }

    public postCabezal(cabezal: Cabezal): Promise<Cabezal> {
        //////console.log("CABEZAL A POSTEAR: ", cabezal);
        return firstValueFrom(
            this.http
                .post<Cabezal>(this.env.API_URL + 'cabezales/', cabezal, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Cabezal) => {
                        return response
                    }),
                ),
        )
    }

    public removeCabezal(cabezal: Cabezal): Promise<Cabezal> {
        return firstValueFrom(
            this.http
                .delete<Cabezal>(
                    this.env.API_URL + 'cabezales/' + cabezal.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Cabezal) => {
                        return response
                    }),
                ),
        )
    }

    //MONTE/////////////////////////////////////////////////////////////
    public postMonte(monte: Monte): Promise<Monte> {
        return firstValueFrom(
            this.http
                .post<Monte>(this.env.API_URL + 'montes/', monte, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Monte) => {
                        return response
                    }),
                    tap((monte: Monte) => {
                        this.fetchMontes()
                        return monte
                    }),
                ),
        )
    }

    public removeMonte(monte: Monte): Promise<Monte> {
        return firstValueFrom(
            this.http
                .delete<Monte>(this.env.API_URL + 'montes/' + monte.uuid, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Monte) => {
                        this.fetchMontes()
                        return response
                    }),
                ),
        )
    }

    public updateMonte(monte: Monte): Promise<Monte> {
        console.log(monte)
        return firstValueFrom(
            this.http
                .put<Monte>(
                    this.env.API_URL + 'montes/' + monte.uuid,
                    {
                        completed: monte.completed,
                        coste_propietario: monte.coste_propietario,
                        coste_servicios_externos:
                            monte.coste_servicios_externos,
                        uuid: monte.uuid,
                        denominacion_casa: monte.denominacion_casa || '',
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Monte) => {
                        return response
                    }),
                    tap((monte: Monte) => {
                        this.fetchMontes()
                        return monte
                    }),
                ),
        )
    }

    //USUARIOS/////////////////////////////////////////////////////////////
    public postUsuario(usuario: Usuario): Promise<Usuario> {
        ////////console.log("POST USER: ", usuario);
        return firstValueFrom(
            this.http
                .post<Usuario>(
                    this.env.API_URL + 'invitation/',
                    {
                        user: {
                            name: usuario.name,
                            email: usuario.email,
                            role: usuario.role,
                        },
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Usuario) => {
                        return response
                    }),
                    tap((usuario: Usuario) => {
                        this.fetchUsuarios()
                        return usuario
                    }),
                ),
        )
    }

    public removeUsuario(usuario: Usuario): Promise<Usuario> {
        return firstValueFrom(
            this.http
                .delete<Usuario>(this.env.API_URL + 'users/' + usuario.uuid, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Usuario) => {
                        this.fetchUsuarios()
                        return response
                    }),
                ),
        )
    }

    public updateUsuario(usuario: Usuario): Promise<Usuario> {
        return firstValueFrom(
            this.http
                .put<Usuario>(
                    this.env.API_URL + 'users/' + usuario.uuid,
                    usuario,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Usuario) => {
                        return response
                    }),
                    tap((usuario: Usuario) => {
                        this.fetchUsuarios()
                        return usuario
                    }),
                ),
        )
    }

    //ASIGNACION/////////////////////////////////////////////////////////////
    public postAsignacion(asignacion: Asignacion): Promise<Asignacion> {
        ////////console.log("Asignacion to post: ", asignacion);
        return firstValueFrom(
            this.http
                .post<Asignacion>(
                    this.env.API_URL + 'asignaciones/',
                    {
                        user_uuid: asignacion.user_uuid,
                        equipo_uuid: asignacion.equipo_uuid,
                        monte_uuid: asignacion.monte_uuid,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Asignacion) => {
                        return response
                    }),
                    tap((asignacion: Asignacion) => {
                        this.fetchAsignaciones()
                        return asignacion
                    }),
                ),
        )
    }

    public removeAsignacion(asignacion: Asignacion): Promise<Asignacion> {
        return firstValueFrom(
            this.http
                .delete<Asignacion>(
                    this.env.API_URL + 'asignaciones/' + asignacion.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Asignacion) => {
                        this.fetchAsignaciones()
                        return response
                    }),
                ),
        )
    }

    public resetData() {
        this.asignaciones.next({ loading: true, value: [] })
        this.myAsignaciones.next({ loading: true, value: [] })
        this.montes.next({ loading: true, value: [] })
        this.horas.next({ loading: true, value: [] })
        this.equipos.next({ loading: true, value: [] })
        this.usuarios.next({ loading: true, value: [] })
        this.cabezales.next({ loading: true, value: [] })
        this.montes_assigned_to_user.next([])
        this.equipos_assigned_to_user.next([])
        this.uniqueActividades.next([])
    }

    public fetchAllData() {
        forkJoin([
            this.fetchMontes(),
            this.fetchHoras(),
            this.fetchEquipos(),
            this.fetchUsuarios(),
            this.fetchAsignaciones(),
            this.fetchCabezales(),
            this.fetchUniqueActividades(),
        ]).subscribe(() => {
            this.isDataReady.next(true)
        })
    }

    /*********************************************************************/
    //** XESTOR FINACIEIRO - FETCH METHODS *******************************/
    /*********************************************************************/
    public async fetchClientes(): Promise<Cliente[]> {
        if (this.clientes.value.loading) return []
        this.clientes.next({ ...this.clientes.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Cliente[]>(this.env.API_URL + 'clientes', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Cliente[]) => {
                        return response || []
                    }),
                    tap((clientes: any) => {
                        var aux = {
                            value: clientes.reverse(),
                            loading: false,
                        }
                        this.clientes.next(aux)
                    }),
                ),
        )
    }

    public async fetchMovimientos(): Promise<Movimiento[]> {
        if (this.movimientos.value.loading) return []
        this.movimientos.next({ ...this.movimientos.value, loading: true })
        return firstValueFrom(
            this.http
                .get<Movimiento[]>(this.env.API_URL + 'movimientos', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Movimiento[]) => {
                        return response || []
                    }),
                    tap((movimientos: Movimiento[]) => {
                        //console.log('MOVIMIENTOS: ', movimientos)
                        var aux = {
                            value: movimientos.reverse(),
                            loading: false,
                        }
                        this.movimientos.next(aux)
                    }),
                ),
        )
    }

    public async fetchPreciosVentas(): Promise<PreciosVenta[]> {
        return firstValueFrom(
            this.http
                .get<PreciosVenta[]>(this.env.API_URL + 'precios_venta', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: PreciosVenta[]) => {
                        ////console.log('PRECIOS VENTA >>>>>>>>: ', response)
                        return response || []
                    }),
                    tap((precios_ventas: any) => {
                        var aux = {
                            value: precios_ventas.reverse(),
                            loading: false,
                        }
                        this.precios_ventas.next(aux)
                    }),
                ),
        )
    }

    public async fetchCosteOperario(user: Usuario): Promise<CosteOperario[]> {
        ////console.log('User: ', user)
        return firstValueFrom(
            this.http
                .get<CosteOperario[]>(this.env.API_URL + 'coste_operario/', {
                    params: { user_uuid: user.uuid },
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario[]) => {
                        return response || []
                    }),
                    tap((coste_operarios: any) => {
                        ////console.log('Coste operario: ', coste_operarios)
                        var aux = {
                            value: coste_operarios.reverse(),
                            loading: false,
                        }
                        this.coste_operarios.next(aux)
                    }),
                ),
        )
    }

    public async fetchCosteEquipo(equipo: Equipo): Promise<CosteEquipo[]> {
        return firstValueFrom(
            this.http
                .get<CosteEquipo[]>(this.env.API_URL + 'coste_equipo/', {
                    params: { equipo_uuid: equipo.uuid },
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteEquipo[]) => {
                        return response || []
                    }),
                    tap((coste_equipos: any) => {
                        var aux = {
                            value: coste_equipos.reverse(),
                            loading: false,
                        }
                        ////console.log('Coste equipo: ', aux)

                        this.coste_equipos.next(aux)
                    }),
                ),
        )
    }

    public async fetchAllCosteOperario(): Promise<CosteOperario[]> {
        return firstValueFrom(
            this.http
                .get<CosteOperario[]>(this.env.API_URL + 'coste_operarios/', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario[]) => {
                        return response || []
                    }),
                    tap((coste_operarios: any) => {
                        var aux = {
                            value: coste_operarios.reverse(),
                            loading: false,
                        }
                        this.coste_operarios.next(aux)
                    }),
                ),
        )
    }

    public async fetchAllCosteEquipo(): Promise<CosteEquipo[]> {
        return firstValueFrom(
            this.http
                .get<CosteEquipo[]>(this.env.API_URL + 'coste_equipos', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteEquipo[]) => {
                        return response || []
                    }),
                    tap((coste_equipos: any) => {
                        var aux = {
                            value: coste_equipos.reverse(),
                            loading: false,
                        }

                        this.coste_equipos.next(aux)
                    }),
                ),
        )
    }

    public async fetchAllCosteMonte(): Promise<CosteMonte[]> {
        return firstValueFrom(
            this.http
                .get<CosteMonte[]>(this.env.API_URL + 'coste_montes', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteMonte[]) => {
                        return response || []
                    }),
                    tap((all_coste_monte: any) => {
                        var aux = {
                            value: all_coste_monte.reverse(),
                            loading: false,
                        }

                        this.all_coste_monte.next(aux)
                    }),
                ),
        )
    }

    public async fetchOtrosDatos(): Promise<OtroDato[]> {
        return firstValueFrom(
            this.http
                .get<OtroDato[]>(this.env.API_URL + 'otros_datos', {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: OtroDato[]) => {
                        return response || []
                    }),
                    tap((otros_datos: any) => {
                        var aux = {
                            value: otros_datos.reverse(),
                            loading: false,
                        }
                        ////console.log('Otros datos: ', aux)
                        this.otros_datos.next(aux)
                    }),
                ),
        )
    }

    /*********************************************************************/
    /** XESTOR FINANCIEIRO - UPDATE/POST METHODS **************************/
    /*********************************************************************/

    //CLIENTES/////////////////////////////////////////////////////////////
    public async postCliente(cliente: Cliente): Promise<Cliente> {
        return firstValueFrom(
            this.http
                .post<Cliente>(this.env.API_URL + 'clientes/', cliente, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Cliente) => {
                        return response
                    }),
                    tap((cliente: Cliente) => {
                        this.fetchClientes()
                    }),
                ),
        )
    }

    public async removeCliente(cliente: Cliente): Promise<Cliente> {
        return firstValueFrom(
            this.http
                .delete<Cliente>(
                    this.env.API_URL + 'clientes/' + cliente.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Cliente) => {
                        this.fetchClientes()
                        return response
                    }),
                ),
        )
    }

    public async updateCliente(cliente: Cliente): Promise<Cliente> {
        return firstValueFrom(
            this.http
                .put<Cliente>(
                    this.env.API_URL + 'clientes/' + cliente.uuid,
                    cliente,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Cliente) => {
                        return response
                    }),
                    tap((cliente: Cliente) => {
                        this.fetchClientes()
                        return cliente
                    }),
                ),
        )
    }

    public async postClientesCSV(csv: any): Promise<Movimiento> {
        var data = new FormData()
        data.append('csv_file', csv)
        return firstValueFrom(
            this.http
                .post<Movimiento>(
                    this.env.API_URL + 'clientes/import_csv',
                    data,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Movimiento) => {
                        ////console.log('response: ', response)
                        return response
                    }),
                    tap((movimiento: Movimiento) => {
                        this.fetchMovimientos()
                        return movimiento
                    }),
                ),
        )
    }

    //MOVIMIENTOS/////////////////////////////////////////////////////////////
    public async postMovimiento(csv: any): Promise<Movimiento> {
        //console.log('csv: ', csv)
        var data = new FormData()
        data.append('csv_file', csv)

        return firstValueFrom(
            this.http
                .post<Movimiento>(
                    this.env.API_URL + 'movimientos/import_csv',
                    data,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: Movimiento) => {
                        ////console.log('response: ', response)
                        return response
                    }),
                    tap((movimiento: Movimiento) => {
                        this.fetchMovimientos()
                        return movimiento
                    }),
                ),
        )
    }

    public async removeMovimiento(movimiento: Movimiento): Promise<Movimiento> {
        return firstValueFrom(
            this.http
                .delete<Movimiento>(
                    this.env.API_URL + 'movimientos/' + movimiento.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Movimiento) => {
                        this.fetchMovimientos()
                        return response
                    }),
                ),
        )
    }

    public async updateMovimiento(movimiento: Movimiento): Promise<Movimiento> {
        return firstValueFrom(
            this.http
                .put<Movimiento>(
                    this.env.API_URL + 'movimientos/' + movimiento.uuid,
                    movimiento,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: Movimiento) => {
                        return response
                    }),
                    tap((movimiento: Movimiento) => {
                        this.fetchMovimientos()
                        return movimiento
                    }),
                ),
        )
    }

    //PRECIOS VENTA/////////////////////////////////////////////////////////////
    public async postPreciosVenta(
        preciosVenta: PreciosVenta,
    ): Promise<PreciosVenta> {
        let date = new Date(preciosVenta.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        preciosVenta.fecha = formattedDate

        return firstValueFrom(
            this.http
                .post<PreciosVenta>(
                    this.env.API_URL + 'precios_venta/',
                    preciosVenta,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: PreciosVenta) => {
                        return response
                    }),
                    tap((preciosVenta: PreciosVenta) => {
                        this.fetchPreciosVentas()
                        return preciosVenta
                    }),
                ),
        )
    }

    public async removePreciosVenta(
        preciosVenta: PreciosVenta,
    ): Promise<PreciosVenta> {
        return firstValueFrom(
            this.http
                .delete<PreciosVenta>(
                    this.env.API_URL + 'precios_venta/' + preciosVenta.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: PreciosVenta) => {
                        this.fetchPreciosVentas()
                        return response
                    }),
                ),
        )
    }

    public async updatePreciosVenta(
        preciosVenta: PreciosVenta,
    ): Promise<PreciosVenta> {
        let date = new Date(preciosVenta.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        preciosVenta.fecha = formattedDate
        return firstValueFrom(
            this.http
                .put<PreciosVenta>(
                    this.env.API_URL + 'precios_venta/' + preciosVenta.uuid,
                    preciosVenta,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: PreciosVenta) => {
                        return response
                    }),
                    tap((preciosVenta: PreciosVenta) => {
                        this.fetchPreciosVentas()
                        return preciosVenta
                    }),
                ),
        )
    }

    //OTROS DATOS/////////////////////////////////////////////////////////////
    public async postOtroDato(otroDato: OtroDato): Promise<OtroDato> {
        return firstValueFrom(
            this.http
                .post<OtroDato>(this.env.API_URL + 'otros_datos/', otroDato, {
                    headers: this.headers,
                })
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: OtroDato) => {
                        return response
                    }),
                    tap((otroDato: OtroDato) => {
                        this.fetchOtrosDatos()
                        return otroDato
                    }),
                ),
        )
    }

    public async removeOtroDato(otroDato: OtroDato): Promise<OtroDato> {
        return firstValueFrom(
            this.http
                .delete<OtroDato>(
                    this.env.API_URL + 'otros_datos/' + otroDato.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: OtroDato) => {
                        this.fetchOtrosDatos()
                        return response
                    }),
                ),
        )
    }

    public async updateOtroDato(otroDato: OtroDato): Promise<OtroDato> {
        return firstValueFrom(
            this.http
                .put<OtroDato>(
                    this.env.API_URL + 'otros_datos/' + otroDato.uuid,
                    otroDato,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: OtroDato) => {
                        return response
                    }),
                    tap((otroDato: OtroDato) => {
                        this.fetchOtrosDatos()
                        return otroDato
                    }),
                ),
        )
    }

    //COSTE OPERARIOS/////////////////////////////////////////////////////////////
    public async postCosteOperario(
        costeOperario: CosteOperario,
    ): Promise<CosteOperario> {
        let date = new Date(costeOperario.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        costeOperario.fecha = formattedDate

        ////console.log(costeOperario)
        return firstValueFrom(
            this.http
                .post<CosteOperario>(
                    this.env.API_URL + 'coste_operarios/',
                    costeOperario,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario) => {
                        return response
                    }),
                    tap((c: CosteOperario) => {
                        this.fetchCosteOperario({
                            uuid: costeOperario.user_uuid,
                        } as Usuario)
                        return c
                    }),
                ),
        )
    }

    public async removeCosteOperario(
        costeOperario: CosteOperario,
    ): Promise<CosteOperario> {
        ////console.log('aquii coste operrio: ', costeOperario)
        return firstValueFrom(
            this.http
                .delete<CosteOperario>(
                    this.env.API_URL + 'coste_operarios/' + costeOperario.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario) => {
                        this.fetchCosteOperario({
                            uuid: costeOperario.user_uuid,
                        } as Usuario)
                        return response
                    }),
                ),
        )
    }

    public async updateCosteOperario(
        costeOperario: CosteOperario,
    ): Promise<CosteOperario> {
        /* Format costeEquipo.fecha to MM/YYYY */
        let date = new Date(costeOperario.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        ////console.log('formattedDate: ', formattedDate)
        ////console.log('costeOperario: ', costeOperario)
        return firstValueFrom(
            this.http
                .put<CosteOperario>(
                    this.env.API_URL + 'coste_operarios/' + costeOperario.uuid,
                    {
                        coste_mes: costeOperario.coste_mes,
                        fecha: formattedDate,
                        titulo: costeOperario.titulo,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)
                        throw error
                    }),
                    map((response: CosteOperario) => {
                        return response
                    }),
                    tap((c: CosteOperario) => {
                        this.fetchCosteOperario(
                            costeOperario.user ??
                                ({ uuid: costeOperario.user_uuid } as Usuario),
                        )
                        return costeOperario
                    }),
                ),
        )
    }

    //COSTE EQUIPOS/////////////////////////////////////////////////////////////
    public async postCosteEquipo(
        costeEquipo: CosteEquipo,
    ): Promise<CosteEquipo> {
        //Sanatize costeEquipo.fecha removing hour, minutes and seconds
        let date = new Date(costeEquipo.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        costeEquipo.fecha = formattedDate
        ////console.log('formattedDate: ', formattedDate)
        return firstValueFrom(
            this.http
                .post<CosteEquipo>(
                    this.env.API_URL + 'coste_equipos/',
                    costeEquipo,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        console.error(error)

                        throw error
                    }),
                    map((response: CosteEquipo) => {
                        return response
                    }),
                    tap((costeOperario: CosteEquipo) => {
                        this.fetchCosteEquipo(
                            costeEquipo.equipo ??
                                ({ uuid: costeEquipo.equipo_uuid } as Equipo),
                        )
                        return costeOperario
                    }),
                ),
        )
    }

    public async removeCosteEquipo(
        costeEquipo: CosteEquipo,
    ): Promise<CosteEquipo> {
        return firstValueFrom(
            this.http
                .delete<CosteEquipo>(
                    this.env.API_URL + 'coste_equipos/' + costeEquipo.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteEquipo) => {
                        this.fetchCosteEquipo(
                            costeEquipo.equipo ??
                                ({ uuid: costeEquipo.equipo_uuid } as Equipo),
                        )
                        return response
                    }),
                ),
        )
    }

    public async updateCosteEquipo(
        costeEquipo: CosteEquipo,
    ): Promise<CosteEquipo> {
        /* Format costeEquipo.fecha to MM/YYYY */
        let date = new Date(costeEquipo.fecha)
        let formattedDate =
            (date.getMonth() + 1).toString().padStart(2, '0') +
            '/' +
            date.getFullYear()
        costeEquipo.fecha = formattedDate
        return firstValueFrom(
            this.http
                .put<CosteEquipo>(
                    this.env.API_URL + 'coste_equipos/' + costeEquipo.uuid,
                    {
                        coste_hora: costeEquipo.coste_hora,
                        fecha: formattedDate,
                        titulo: costeEquipo.titulo,
                    },
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)

                        //Check if error is 409
                        throw error
                    }),
                    map((response: CosteEquipo) => {
                        return response
                    }),
                    tap((response: CosteEquipo) => {
                        this.fetchCosteEquipo(
                            costeEquipo.equipo ??
                                ({ uuid: costeEquipo.equipo_uuid } as Equipo),
                        )
                        return response
                    }),
                ),
        )
    }

    //COSTE MONTES/////////////////////////////////////////////////////////////
    public async postCosteMonte(csv: any): Promise<CosteMonte> {
        var data = new FormData()
        data.append('csv_file', csv)

        return firstValueFrom(
            this.http
                .post<CosteMonte>(
                    this.env.API_URL + 'montes/import_csv',
                    data,
                    { headers: this.headers },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteMonte) => {
                        ////console.log('response: ', response)
                        return response
                    }),
                    tap((all_coste_montes: CosteMonte) => {
                        this.fetchAllCosteMonte()
                        return all_coste_montes
                    }),
                ),
        )
    }

    public async removeCosteMonte(costeMonte: CosteMonte): Promise<CosteMonte> {
        return firstValueFrom(
            this.http
                .delete<CosteMonte>(
                    this.env.API_URL + 'coste_montes/' + costeMonte.uuid,
                    {
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: CosteMonte) => {
                        this.fetchAllCosteMonte()
                        return response
                    }),
                ),
        )
    }

    /*********************************************************************/

    /*********************************************************************/
    //** RENTABILIDADES - GET METHODS **********************************/
    /*********************************************************************/
    /* RENTABILIDAD POR MONTE */
    public async fetchRentabilidadMonte(
        monte: Monte,
    ): Promise<RentabilidadMonte> {
        return firstValueFrom(
            this.http
                .get<RentabilidadMonte>(
                    this.env.API_URL + 'rentabilidad_monte',
                    {
                        params: { codigo_monte: monte.codigo_monte },
                        headers: this.headers,
                    },
                )
                .pipe(
                    catchError((error) => {
                        //console.error(error)
                        throw error
                    }),
                    map((response: RentabilidadMonte) => {
                        return response || []
                    }),
                    tap((rentabilidad: RentabilidadMonte) => {
                        this.rentabilidad_monte.next(rentabilidad)
                    }),
                ),
        )
    }

    /* RENTABILIDAD POR MES */
    public async fetchRentabilidadMes(
        fecha: string,
    ): Promise<RentabilidadMes[]> {
        var aux = {
            value: [] as RentabilidadMes[],
            loading: true,
        }
        this.rentabilidad_mes.next(aux)
        var formData = new FormData()
        formData.append('fecha', fecha)
        ////console.log('fecha: ', fecha)
        return firstValueFrom(
            this.http
                .get<RentabilidadMes[]>(this.env.API_URL + 'rentabilidad_mes', {
                    params: { fecha: fecha },
                    headers: this.headers,
                })
                .pipe(
                    map((response: RentabilidadMes[]) => {
                        ////console.log('response: ', response)
                        return response || []
                    }),
                    tap((rentabilidad: RentabilidadMes[]) => {
                        var aux = {
                            value: rentabilidad,
                            loading: false,
                        }
                        this.rentabilidad_mes.next(aux)
                    }),
                ),
        )
    }

    async isReady() {
        //Promise that returns if the token is ready or not
        const isReady = new Promise((resolve, reject) => {
            this.isDataReady.subscribe((ready) => {
                if (ready) {
                    resolve(true)
                }
            })
        })

        return isReady
    }
}
