import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule, ModalController } from '@ionic/angular'

interface ChangelogEntry {
    version: string
    date: Date
    changes: string[]
}

@Component({
    selector: 'app-changelog',
    templateUrl: './changelog.component.html',
    styleUrls: ['./changelog.component.scss'],
    standalone: true,
    imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule],
})
export class ChangelogComponent implements OnInit {
    @Input() changelog: ChangelogEntry[] = [
        {
            version: '1.3.3',
            date: new Date('2024-06-10'),
            changes: [
                'Solución de errores y mejora de estabilidad.',
                'Visualización desde la interfaz de changelog y accesslog',
            ],
        },
        {
            version: '1.3.2',
            date: new Date('2024-06-06'),
            changes: ['Solución de errores y mejora de estabilidad.'],
        },
        {
            version: '1.3.1',
            date: new Date('2024-06-05'),
            changes: ['Solución de errores y mejora de estabilidad.'],
        },
        {
            version: '1.3.0',
            date: new Date('2024-06-03'),
            changes: [
                'Nuevos tipos de productos',
                'Nuevos campos de CSV de movimientos',
                'Atributo título en costes de trabajador',
                'Atributos título y cod_fabrica en precios/venta',
                'Nuevos filtros en página de actividades, movimientos y precios/venta',
                'Resumen de estadísticas en páginas de actividades, movimientos y precios/venta',
                'Avisos en las rentabilidades de falta de datos o de datos completados',
                'Cálculo de rentabilidades por equipo y visualización en plataforma',
            ],
        },
        {
            version: '1.2.7',
            date: new Date('2024-05-27'),
            changes: ['Solución de errores y mejora de estabilidad.'],
        },
        {
            version: '1.2.0',
            date: new Date('2024-05-18'),
            changes: [
                'Gestión de clientes',
                'Gestión de movimientos y funcionalidad de importado de CSV de movimientos',
                'Gestión de precios/venta',
                'Gestión de costes de trabajador',
                'Gestión de costes de maquinaria',
                'Gestión de otros datos: subvenciones, certificaciones, combustible.',
                'Sección de rentabilidades por monte',
            ],
        },
        {
            version: '1.1.5',
            date: new Date('2024-05-01'),
            changes: [
                'Solución de errores y mejora de estabilidad.',
                'Optimización del acceso y gestión de datos para reducir tiempos de carga.',
                'Configuración de pantallas de loading',
                'Cálculos de rentabilidad por monte',
            ],
        },
        {
            version: '1.1.0',
            date: new Date('2024-01-18'),
            changes: [
                'Las actividades de los motoserristas y peones son: Avería, Traballo y Otros',
                'Reconfiguración del gestor de cabezales',
                'Reconfiguración formularios de actividades y comandas. Automatización en la selección de cabezales y actividades que puede realizar ese cabezal.',
            ],
        },
        {
            version: '1.0.3',
            date: new Date('2023-12-27'),
            changes: [
                'Plataforma para gestión de actividades en montes.',
                'Sistema de gestión de comandas y actividades',
                'Automatización del rellenado formularios para simplificar el uso de la plataforma por parte de los Peones, Maquinistas y Motoserristas',
                'Retoques en la interfaz de usuario',
                'Sistema de cálculos de rentabilidades en backend',
            ],
        },
        {
            version: '1.0.2',
            date: new Date('2023-11-27'),
            changes: ['Solución de errores y mejora de estabilidad.'],
        },
        {
            version: '1.0.1',
            date: new Date('2023-10-14'),
            changes: [
                'Plataforma para fichado de horas de trabajo.',
                'Aplicación Android, iOS y Web',
                'Roles de usuario: Peón, Motoserrista, Maquinista, Administrador',
                'Gestión de recursos: Traballadores, Equipo, Cabezales y Montes',
                'Gestión de usuarios y cuentas',
            ],
        },
    ]
    constructor(private modalController: ModalController) {}

    ngOnInit() {}

    closeModal() {
        this.modalController.dismiss()
    }
}
