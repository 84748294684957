import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './guard/auth.guard'

const routes: Routes = [
    {
        path: '',
        redirectTo: '/landing',
        pathMatch: 'full',
    },
    {
        path: 'landing',
        loadChildren: () =>
            import('./pages/landing/landing.module').then(
                (m) => m.LandingPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./pages/dashboard/dashboard.module').then(
                (m) => m.DashboardPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'equipos',
        loadChildren: () =>
            import('./pages/equipos/equipos.module').then(
                (m) => m.EquiposPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'cabezales',
        loadChildren: () =>
            import('./pages/cabezales/cabezales.module').then(
                (m) => m.CabezalesPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'asignaciones',
        loadChildren: () =>
            import('./pages/asignaciones/asignaciones.module').then(
                (m) => m.AsignacionesPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'usuarios',
        loadChildren: () =>
            import('./pages/usuarios/usuarios.module').then(
                (m) => m.UsuariosPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'montes',
        loadChildren: () =>
            import('./pages/montes/montes.module').then(
                (m) => m.MontesPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./pages/auth/profile/profile.module').then(
                (m) => m.ProfilePageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'auth/reset_password',
        loadChildren: () =>
            import('./pages/auth/reset-password/reset-password.module').then(
                (m) => m.ResetPasswordPageModule,
            ),
    },
    {
        path: 'retrieve-password',
        loadChildren: () =>
            import(
                './pages/auth/retrieve-password/retrieve-password.module'
            ).then((m) => m.RetrievePasswordPageModule),
    },

    {
        path: 'invitation/accept',
        loadChildren: () =>
            import('./pages/auth/register/register.module').then(
                (m) => m.RegisterPageModule,
            ),
    },

    /****** XESTOR FINANCIEIRO ****************************/
    {
        path: 'clientes',
        loadChildren: () =>
            import('./pages/clientes/clientes.module').then(
                (m) => m.ClientesPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'movimientos',
        loadChildren: () =>
            import('./pages/movimientos/movimientos.module').then(
                (m) => m.MovimientosPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'precios-ventas',
        loadChildren: () =>
            import('./pages/precios-ventas/precios-ventas.module').then(
                (m) => m.PreciosVentasPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'coste-operarios',
        loadChildren: () =>
            import('./pages/coste-operarios/coste-operarios.module').then(
                (m) => m.CosteOperariosPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'coste-equipos',
        loadChildren: () =>
            import('./pages/coste-equipos/coste-equipos.module').then(
                (m) => m.CosteEquiposPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'coste-montes',
        loadChildren: () =>
            import('./pages/coste-montes/coste-montes.module').then(
                (m) => m.CosteMontesPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'otros-datos',
        loadChildren: () =>
            import('./pages/otros-datos/otros-datos.module').then(
                (m) => m.OtrosDatosPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'rentabilidad-mes',
        loadChildren: () =>
            import('./pages/rentabilidad-mes/rentabilidad-mes.module').then(
                (m) => m.RentabilidadMesPageModule,
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'rentabilidad-monte',
        loadChildren: () =>
            import('./pages/rentabilidad-monte/rentabilidad-monte.module').then(
                (m) => m.RentabilidadMontePageModule,
            ),
    },
    {
        path: 'rentabilidad-equipo',
        loadChildren: () =>
            import(
                './pages/rentabilidad-equipo/rentabilidad-equipo.module'
            ).then((m) => m.RentabilidadEquipoPageModule),
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
