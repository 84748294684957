import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EnvService {
  API_URL = environment.API_URL;
  DEBUG = !environment.production;
  MOBILE_WIDTH = 573;
  token: BehaviorSubject<string> = new BehaviorSubject('');


  constructor() { }
}
