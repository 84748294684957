import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule, ModalController } from '@ionic/angular'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'

interface AccessLogEntry {
    timestamp: Date
    username: string
    action: string
    details?: string
}

@Component({
    selector: 'app-access-log',
    templateUrl: './access-log.component.html',
    styleUrls: ['./access-log.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule,
    ],
})
export class AccessLogComponent implements OnInit {
    @Input() accessLog: AccessLogEntry[] = [
        {
            timestamp: new Date('2024-04-12T08:15:14.664Z'),
            username: 'maderas@diazramos.net',
            action: 'Iniciado POST /login',
            details: 'IP: 91.126.40.124',
        },
        {
            timestamp: new Date('2024-04-12T08:15:15.666Z'),
            username: 'maderas@diazramos.net',
            action: 'Procesando inicio de sesión',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-04-12T08:15:15.668Z'),
            username: 'maderas@diazramos.net',
            action: 'Enviando parámetros',
            details: 'Email: maderas@diazramos.net, Password: [FILTERED]',
        },
        {
            timestamp: new Date('2024-04-22T16:35:19.933Z'),
            username: 'adrixove@gmail.com',
            action: 'Procesando invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-04-22T16:35:19.935Z'),
            username: 'adrixove@gmail.com',
            action: 'Enviando parámetros',
            details:
                'Nombre: ADRIAN, Email: adrixove@gmail.com, Rol: maquinista',
        },
        {
            timestamp: new Date('2024-04-22T16:35:20.400Z'),
            username: 'adrixove@gmail.com',
            action: 'Correo de invitación renderizado',
            details: 'Duración: 4.6ms',
        },
        {
            timestamp: new Date('2024-03-30T12:00:19.678Z'),
            username: 'd.fernandez@nigal.net',
            action: 'Procesando invitación',
            details: 'Formato: */*',
        },
        {
            timestamp: new Date('2024-03-30T12:00:19.680Z'),
            username: 'd.fernandez@nigal.net',
            action: 'Enviando parámetros',
            details: 'Nombre: D2, Email: d.fernandez@nigal.net, Rol: admin',
        },
        {
            timestamp: new Date('2024-03-30T12:00:19.689Z'),
            username: 'd.fernandez@nigal.net',
            action: 'Correo de invitación renderizado',
            details: 'Duración: 0.9ms',
        },
        {
            timestamp: new Date('2024-02-14T09:22:14.664Z'),
            username: 'daniel@diazramos.es',
            action: 'Iniciado POST /invitation',
            details: 'IP: 91.126.40.124',
        },
        {
            timestamp: new Date('2024-02-14T09:22:14.666Z'),
            username: 'daniel@diazramos.es',
            action: 'Procesando invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-02-14T09:22:14.668Z'),
            username: 'daniel@diazramos.es',
            action: 'Enviando parámetros',
            details:
                'Nombre: Daniel Diaz Ramos, Email: daniel@diazramos.es, Rol: admin',
        },
        {
            timestamp: new Date('2024-02-14T09:22:15.249Z'),
            username: 'daniel@diazramos.es',
            action: 'Correo de invitación renderizado',
            details: 'Duración: 2.1ms',
        },
        {
            timestamp: new Date('2024-05-04T09:22:14.664Z'),
            username: 'daniel@diazramos.es',
            action: 'Iniciado POST /invitation',
            details: 'IP: 91.126.40.124',
        },
        {
            timestamp: new Date('2024-05-04T09:22:14.666Z'),
            username: 'daniel@diazramos.es',
            action: 'Procesando invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-05-04T09:22:14.666Z'),
            username: 'daniel@diazramos.es',
            action: 'Enviando parámetros',
            details:
                'Nombre: Daniel Diaz Ramos, Email: daniel@diazramos.es, Rol: admin',
        },
        {
            timestamp: new Date('2024-05-04T09:22:15.248Z'),
            username: 'daniel@diazramos.es',
            action: 'Correo de invitación renderizado',
            details: 'Duración: 2.3ms',
        },
        {
            timestamp: new Date('2024-05-04T09:24:23.380Z'),
            username: 'daniel@diazramos.es',
            action: 'Procesando invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-05-04T09:24:23.380Z'),
            username: 'daniel@diazramos.es',
            action: 'Enviando parámetros',
            details:
                'Nombre: Daniel Diaz Ramos, Email: daniel@diazramos.es, Rol: admin',
        },
        {
            timestamp: new Date('2024-05-04T09:24:23.394Z'),
            username: 'daniel@diazramos.es',
            action: 'Correo de invitación renderizado',
            details: 'Duración: 0.6ms',
        },
        {
            timestamp: new Date('2024-05-04T09:46:19.678Z'),
            username: 'd.fernandez@nigal.net',
            action: 'Procesando invitación',
            details: 'Formato: */*',
        },
        {
            timestamp: new Date('2024-05-04T09:46:19.678Z'),
            username: 'd.fernandez@nigal.net',
            action: 'Enviando parámetros',
            details: 'Nombre: D2, Email: d.fernandez@nigal.net, Rol: admin',
        },
        {
            timestamp: new Date('2024-05-19T21:00:35.423Z'),
            username: 'unknown',
            action: 'Procesando actualización de invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-05-19T21:00:35.423Z'),
            username: 'unknown',
            action: 'Enviando parámetros',
            details: 'Password: [FILTERED], Invitation Token: [FILTERED]',
        },
        {
            timestamp: new Date('2024-05-19T21:54:24.703Z'),
            username: 'unknown',
            action: 'Iniciado PUT /invitation',
            details: 'IP: 91.126.102.4',
        },
        {
            timestamp: new Date('2024-05-19T21:54:24.703Z'),
            username: 'unknown',
            action: 'Procesando actualización de invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-05-19T21:54:24.703Z'),
            username: 'unknown',
            action: 'Enviando parámetros',
            details: 'Password: [FILTERED], Invitation Token: [FILTERED]',
        },
        {
            timestamp: new Date('2024-05-19T21:54:25.042Z'),
            username: 'unknown',
            action: 'Actualización de invitación completada',
            details: 'Estado: 200 OK',
        },
        {
            timestamp: new Date('2024-05-19T21:54:49.432Z'),
            username: 'marcosmaseda@hotmail.com',
            action: 'Iniciado POST /login',
            details: 'IP: 91.126.102.4',
        },
        {
            timestamp: new Date('2024-05-19T21:54:49.433Z'),
            username: 'marcosmaseda@hotmail.com',
            action: 'Procesando inicio de sesión',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-05-19T21:54:49.433Z'),
            username: 'marcosmaseda@hotmail.com',
            action: 'Enviando parámetros',
            details: 'Email: marcosmaseda@hotmail.com, Password: [FILTERED]',
        },
        {
            timestamp: new Date('2024-05-23T20:15:23.709Z'),
            username: 'unknown',
            action: 'Iniciado PUT /invitation',
            details: 'IP: 2.141.25.54',
        },
        {
            timestamp: new Date('2024-05-23T20:15:23.711Z'),
            username: 'unknown',
            action: 'Procesando actualización de invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-05-23T20:15:23.711Z'),
            username: 'unknown',
            action: 'Enviando parámetros',
            details: 'Password: [FILTERED], Invitation Token: [FILTERED]',
        },
        {
            timestamp: new Date('2024-06-04T15:35:19.933Z'),
            username: 'adrixove@gmail.com',
            action: 'Procesando invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-06-04T15:35:19.933Z'),
            username: 'adrixove@gmail.com',
            action: 'Enviando parámetros',
            details:
                'Nombre: ADRIAN, Email: adrixove@gmail.com, Rol: maquinista',
        },
        {
            timestamp: new Date('2024-06-04T15:35:20.507Z'),
            username: 'adrixove@gmail.com',
            action: 'Correo de invitación renderizado',
            details: 'Duración: 4.4ms',
        },
        {
            timestamp: new Date('2024-06-04T16:00:19.789Z'),
            username: 'unknown',
            action: 'Iniciado PUT /invitation',
            details: 'IP: 95.124.176.186',
        },
        {
            timestamp: new Date('2024-06-04T16:00:19.790Z'),
            username: 'unknown',
            action: 'Procesando actualización de invitación',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-06-04T16:00:19.790Z'),
            username: 'unknown',
            action: 'Enviando parámetros',
            details: 'Password: [FILTERED], Invitation Token: [FILTERED]',
        },
        {
            timestamp: new Date('2024-06-04T16:03:02.797Z'),
            username: 'lgarciaeijo@gmail.com',
            action: 'Iniciado POST /login',
            details: 'IP: 95.124.176.186',
        },
        {
            timestamp: new Date('2024-06-04T16:03:02.798Z'),
            username: 'lgarciaeijo@gmail.com',
            action: 'Procesando inicio de sesión',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-06-04T16:03:02.798Z'),
            username: 'lgarciaeijo@gmail.com',
            action: 'Enviando parámetros',
            details: 'Email: lgarciaeijo@gmail.com, Password: [FILTERED]',
        },
        {
            timestamp: new Date('2024-06-04T21:54:04.962Z'),
            username: 'mariusandrei29071983@gmail.com',
            action: 'Iniciado POST /login',
            details: 'IP: 188.85.121.12',
        },
        {
            timestamp: new Date('2024-06-04T21:54:04.963Z'),
            username: 'mariusandrei29071983@gmail.com',
            action: 'Procesando inicio de sesión',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-06-04T21:54:04.963Z'),
            username: 'mariusandrei29071983@gmail.com',
            action: 'Enviando parámetros',
            details:
                'Email: mariusandrei29071983@gmail.com, Password: [FILTERED]',
        },
        {
            timestamp: new Date('2024-06-05T08:22:14.664Z'),
            username: 'maderas@diazramos.net',
            action: 'Iniciado POST /login',
            details: 'IP: 91.126.40.124',
        },
        {
            timestamp: new Date('2024-06-05T08:22:14.666Z'),
            username: 'maderas@diazramos.net',
            action: 'Procesando inicio de sesión',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-06-05T08:22:14.666Z'),
            username: 'maderas@diazramos.net',
            action: 'Enviando parámetros',
            details: 'Email: maderas@diazramos.net, Password: [FILTERED]',
        },
        {
            timestamp: new Date('2024-06-10T10:15:14.664Z'),
            username: 'maderas@diazramos.net',
            action: 'Iniciado POST /login',
            details: 'IP: 91.126.40.124',
        },
        {
            timestamp: new Date('2024-06-10T10:15:14.666Z'),
            username: 'maderas@diazramos.net',
            action: 'Procesando inicio de sesión',
            details: 'Formato: JSON',
        },
        {
            timestamp: new Date('2024-06-10T10:15:14.666Z'),
            username: 'maderas@diazramos.net',
            action: 'Enviando parámetros',
            details: 'Email: maderas@diazramos.net, Password: [FILTERED]',
        },
    ].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())

    constructor(private modalController: ModalController) {}

    ngOnInit(): void {}

    closeModal() {
        this.modalController.dismiss()
    }
}
