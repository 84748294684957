import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage-angular'

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    constructor(private storage: Storage) {
        this.storage.create()
    }

    public async setItem(reference: string, value: any): Promise<any> {
        try {
            await this.storage.set(reference, value)
        } catch (error) {
            //console.error(`Error setting item ${reference} in local storage`, error);
            throw error
        }
    }

    public async getItem(reference: string): Promise<any> {
        try {
            return await this.storage.get(reference)
        } catch (error) {
            //console.error(`Error getting item ${reference} from local storage`, error);
            throw error
        }
    }

    public async remove(reference: string): Promise<any> {
        try {
            return await this.storage.remove(reference)
        } catch (error) {
            //console.error(`Error removing item ${reference} from local storage`, error);
            throw error
        }
    }
}
