import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { IonicModule } from '@ionic/angular'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { ServicesModule } from './services/services.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HashLocationStrategy, LocationStrategy } from '@angular/common'
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker'
import { IonicSelectableComponent } from 'ionic-selectable'
import { defineLocale, esLocale } from 'ngx-bootstrap/chronos'
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BsDatepickerModule.forRoot(),
        IonicModule.forRoot(),
        AppRoutingModule,
        ServicesModule,
        BrowserAnimationsModule,
        IonicSelectableComponent,
    ],
    //providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
    //HashLocationStrategy instead of IonicRouteStrategy
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],

    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(localeService: BsLocaleService) {
        defineLocale('es', esLocale)
        localeService.use('es')
    }
}
