<ion-header>
    <ion-toolbar>
        <ion-title>Histórico de versiones</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="closeModal()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    <div class="changelog-container">
        <h1 style="color: var(--ion-color-primary); font-weight: bold">
            Versión Actual: {{ changelog[0].version }}
        </h1>
        <div class="timeline">
            <div
                *ngFor="let entry of changelog; let i = index"
                class="timeline-item">
                <div class="timeline-content">
                    <h2 style="color: var(--ion-color-primary)">
                        {{ entry.version }} -
                        {{ entry.date | date : 'mediumDate' }}
                    </h2>
                    <ul>
                        <li *ngFor="let change of entry.changes">
                            <label style="color: grey">{{ change }}</label>
                        </li>
                    </ul>
                </div>
                <div class="timeline-circle"></div>
            </div>
        </div>
    </div>
</ion-content>
