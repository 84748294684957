import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import Swal, { SweetAlertIcon } from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(public loadingController: LoadingController) {}

  async showAlert(
    title: string,
    message: string,
    icon?: SweetAlertIcon
  ): Promise<void> {
    await Swal.fire({
      title: title,
      text: message,
      icon: icon || "success",
      confirmButtonText: "OK",
      heightAuto: false,
    });
  }

  async showAlertMessage(
    message: string,
    icon?: SweetAlertIcon
  ): Promise<void> {
    await Swal.fire({
      text: message,
      icon: icon || "success",
      confirmButtonText: "OK",
      confirmButtonColor: "var(--ion-color-primary)",
      heightAuto: false,
    });
  }

  showInputMessage(title: string, message: string): Promise<any> {
    return Swal.fire({
      title: title,
      text: message,
      input: 'text',
      showCancelButton: true,
      confirmButtonColor: "var(--ion-color-warning)",
      confirmButtonText: "Gardar",
      focusConfirm: false,
      heightAuto: false,
    })
  }

  async success(title: string, message: string): Promise<void> {
    await this.showAlert(title, message, "success");
  }

  async error(title: string, message: string): Promise<void> {
    await this.showAlert(title, message, "error");
  }

  async showHTMLAlert(title: string, htmlMessage: string): Promise<void> {
    await Swal.fire({
      title: title,
      html: htmlMessage,
      width: "60%",
      confirmButtonText: "Cerrar",
      heightAuto: false,
    });
  }

  async confirm(
    title: string,
    message: string,
    icon?: SweetAlertIcon
  ): Promise<boolean> {
    const result = await Swal.fire({
      title: title,
      html: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      confirmButtonColor: "var(--ion-color-primary)",
      cancelButtonText: "Cancelar",
      heightAuto: false,
    });
    return result.isConfirmed;
  }

  async showCopyUrlPrompt(url: string): Promise<void> {
    //Modal with input to copy url
    //the input must be readonly

    await Swal.fire({
      title: "Copiar URL",
      input: "text",
      inputValue: url,
      inputAttributes: {
        readonly: "true",
      },
      heightAuto: false,
    });
  }

  async presentLoading(message: string, duration: number): Promise<void> {
    this.loadingController
      .create({
        message: message,
        spinner: "crescent",
        duration: duration,
      })
      .then((res) => {
        res.present();
      });
  }
}
